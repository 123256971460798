<template>
    <div class="xunJianBarChart"></div>
</template>

<script>
export default {
    name: '',
    data() {
        return {}
    },
    methods: {
        setChart() {
            let option = {
              xAxis: {
                type: 'category',
                //data: ['待巡检', '已巡',  '异常','漏巡','公共区域','专用教室','教学场所','重点部位安全管理'],
                data: ['未完成数', '已巡检',  '异常点','漏巡点'],
                splitLine:{
                  show:false
                },
                axisLabel: {
                  color: '#61B9C8',
                  fontSize: 9,
                  interval: 0, rotate: 38
                },
              },
              tooltip: {
                trigger: 'axis',
              },
              grid: {
                top: '12%',
                left: '10%',
                right: '10%',
                bottom: '10%',
                containLabel: false
              },
              barWidth: 20,
              yAxis: {
                type: 'value',
                splitLine:{
                  show:false
                },
                axisLabel: {
                  color: '#61B9C8',
                  fontSize: 9
                },
              },
              series: [
                {
                  data: [
                    {
                      value: 20,
                      itemStyle: {
                        color: '#c2232a'
                      }
                    },
                    {
                      value: 23,
                      itemStyle: {
                        color: '#fe672c'
                      }
                    },
                    {
                      value: 6,
                      itemStyle: {
                        color: '#a262f2'
                      }
                    },
                    {
                      value: 11,
                      itemStyle: {
                        color: '#2870e8'
                      }
                    },


                  ],
                  type: 'bar'
                }
              ]
            };

            let myChart = this.$echarts(this.$el);
            myChart.clear();
            myChart.resize()
            myChart.setOption(option);
        }
    },
    mounted() {
        this.setChart()
    },
}
</script>

<style lang="less" scoped>
.xunJianBarChart {
    height: 100%;
    width: 100%;
    bottom: 8%;
}
</style>
